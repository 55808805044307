<template>
  <div class="row d-flex justify-content-center">
    <div class="col-md-12">
      <card>
        <p />
        <div>
          <h5>
            Chose which venues to assign to which users:
          </h5>
        </div>
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-5">
              <fg-input name="User" label="User Name">
                <el-select
                  class="select-primary"
                  v-model="assign_user"
                  placeholder="choose user"
                  filterable
                  clearable
                >
                  <el-option
                    class="select-primary"
                    v-for="(item, index) in users"
                    :key="item.id"
                    :label="item.first_name + ' ' + item.last_name"
                    :value="index"
                  >
                  </el-option>
                </el-select>
              </fg-input>
            </div>
          </div>
          <user-venues-list :user="users[assign_user]"> </user-venues-list>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { Select, Option } from "element-ui";
import Card from "../../../../components/Cards/Card.vue";
import UserVenuesList from "src/pages/Dashboard/Pages/UserProfile/UserVenuesList.vue";
import { initAPI } from "src/gateway/api-instance.js";

extend("required", required);
export default {
  name: "Venues",
  components: {
    Card,
    UserVenuesList,
    [Select.name]: Select,
    [Option.name]: Option
  },
  data() {
    return {
      users: [],
      assign_user: "",
      venue: "",
      venues: []
    };
  },
  computed: {
    // This is a computed variable, that it's value is recomputed every time it's used throughout the component.
    Instance() {
      return initAPI(this.$cookies.get("Token"), process.env.VUE_APP_API_URL);
    }
  },
  methods: {
    /*
      - Calls a GET API to get a list of users in the database.
    */
    getUsers() {
      let vm = this;
      this.Instance.get("users")
        .then(function(response) {
          vm.users = response.data;
        })
        .catch(function(error) {
          console.error(error);
        });
    }
  },
  mounted() {
    this.getUsers();
  }
};
</script>
<style>
.v-input__slot {
  position: relative;
  transform: translateX(8%) translateY(5%);
}
</style>
