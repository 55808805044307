<template>
  <span>
    Welcome to Barvision configuration web app.
  </span>
</template>
<script>
export default {
  name: "notification-sample",
  props: {
    message: {
      Type: String,
      default: ""
    }
  }
};
</script>
<style></style>
