<template>
  <div class="user">
    <div class="photo">
      <img src="static/img/login-logo.png" alt="avatar" />
    </div>
    <div class="info">
      <a :href="hrefURL">
        <span>
          {{ title }}
        </span>
      </a>
      <div class="clearfix"></div>
      <!--<div>
        <el-collapse-transition>
          <ul class="nav" v-show="!isClosed">
            <slot>
              <li>
                <a class="profile-dropdown" href="/pages/user">
                  <span class="sidebar-mini">MP</span>
                  <span class="sidebar-normal">My Profile</span>
                </a>
              </li>
              <li>
                <a class="profile-dropdown" href="#pablo">
                  <span class="sidebar-mini">EP</span>
                  <span class="sidebar-normal">Edit Profile</span>
                </a>
              </li>
              <li>
                <a class="profile-dropdown" href="#pablo">
                  <span class="sidebar-mini">S</span>
                  <span class="sidebar-normal">Settings</span>
                </a>
              </li>
            </slot>
          </ul>
        </el-collapse-transition>
      </div>-->
    </div>
  </div>
</template>
<script>
import CollapseTransition from "element-ui/lib/transitions/collapse-transition";

export default {
  components: {
    [CollapseTransition.name]: CollapseTransition
  },
  props: {},
  // All variables in the computed clause, are re-computed everytime they're used in the code.
  computed: {
    // the title of the user menu is the username of the signed in user.
    title() {
      var vm = this;
      return vm.$cookies.get("Username");
    },
    // When the user name is clicked the user should be directed to their profile page, so we get the url from the app environments.
    hrefURL() {
      return process.env.VUE_APP_HREF_URL + "#/user";
    }
  },
  data() {
    return {
      isClosed: true
    };
  },
  methods: {
    toggleMenu() {
      this.isClosed = !this.isClosed;
    }
  }
};
</script>
<style>
.collapsed {
  transition: opacity 1s;
}
</style>
