/*!

=========================================================
* Vue Light Bootstrap Dashboard - v1.3.1
=========================================================

* Product Page: http://www.creative-tim.com/product/vue-light-bootstrap-dashboard-pro
* Copyright 2019 Creative Tim (http://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard/blob/master/LICENSE.md)

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import LightBootstrap from './light-bootstrap-main'
// Plugins
import App from './App.vue'

// vuelidate plugin
import Vuelidate from "vuelidate";
Vue.config.productionTip = false;
Vue.use(Vuelidate);

/*// API instance setup
import { Instance, initAPI} from "./gateway/api-instance";
Vue.use(Instance);
Vue.use(initAPI);*/

// router setup
import router from './routes/routes'
// plugin setup
Vue.use(VueRouter)
Vue.use(LightBootstrap)

// Coockies setup
// require
import VueCookies from 'vue-cookies'

Vue.use(require('vue-cookies'))
Vue.use(VueCookies)
Vue.$cookies.config('7d')

import moment from 'moment'
 
Vue.use(require('vue-moment'));
Vue.prototype.moment = moment


/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: h => h(App),
  router
})
