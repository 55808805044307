import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import DashboardLayout from 'src/pages/Dashboard/Layout/DashboardLayout.vue'
// GeneralViews
import NotFound from 'src/pages/GeneralViews/NotFoundPage.vue'
// Dashboard pages
import Overview from 'src/pages/Dashboard/Dashboard/Overview.vue'
import Stats from 'src/pages/Dashboard/Dashboard/Stats.vue'

// User pages
import User from 'src/pages/Dashboard/Pages/UserProfile.vue'
import Login from 'src/pages/Dashboard/Pages/Login.vue'
import Register from 'src/pages/Dashboard/Pages/Register.vue'
import Lock from 'src/pages/Dashboard/Pages/Lock.vue'
import SetPassword from 'src/pages/Dashboard/Pages/UserProfile/SetPassword.vue'
import { email } from 'vuelidate/lib/validators'

// Customer pages
const CustomerOnboardingRequests = () => import('src/pages/Dashboard/Pages/Customer/CustomerOnboardingRequests.vue')
const LiquorList = () => import('src/pages/Dashboard/Pages/Customer/LiquorList.vue')
const NewCustomer = () => import('src/pages/Dashboard/Pages/Customer/NewCustomer.vue')
const UpdateCustomer = () => import('src/pages/Dashboard/Pages/Customer/UpdateCustomer.vue')
const EditPOSCredentials = () => import('src/pages/Dashboard/Pages/Customer/EditPOSCredentials.vue')
const StartPullingData = () => import('src/pages/Dashboard/Pages/Customer/StartPullingData.vue')

//POS Systems pages
const DataIngestionMonitor = () => import('src/pages/Dashboard/Pages/POSSystems/DataIngestionMonitor.vue')
const PosCredentialsManager = () => import('src/pages/Dashboard/Pages/POSSystems/PosCredentialsManager.vue')
const PosSystemsManager = () => import('src/pages/Dashboard/Pages/POSSystems/PosSystemsManager.vue')

//Venues pages
const NewRecipeList = () => import('src/pages/Dashboard/Pages/Venues/NewRecipeList.vue')
const MonitoredDrinks = () => import('src/pages/Dashboard/Pages/Venues/MonitoredDrinks.vue')
const UnmonitoredDrinks = () => import('src/pages/Dashboard/Pages/Venues/UnmonitoredDrinks.vue')
const DrinkList = () => import('src/pages/Dashboard/Pages/Venues/DrinkList.vue')

//Spouts pages
const SpoutHistory = () => import('src/pages/Dashboard/Pages/Spouts/SpoutHistory.vue')
const SpoutsManager = () => import('src/pages/Dashboard/Pages/Spouts/SpoutsManager.vue')

//Tables pages
const MasterDrinkList = () => import('src/pages/Dashboard/Pages/Tables/MasterDrinkList.vue')
const MasterLiquorList = () => import('src/pages/Dashboard/Pages/Tables/MasterLiquorList.vue')
const MasterPosSystemTable = () => import('src/pages/Dashboard/Pages/Tables/MasterPosSystemTable.vue')

//Menu psge
const MenuItemsMonitor = () => import('src/pages/Dashboard/Pages/Menu/MenuItemsMonitor.vue')
const PluCodeMonitor = () => import('src/pages/Dashboard/Pages/Menu/PluCodeMonitor.vue')


// Calendar
const Calendar = () => import('src/pages/Dashboard/Calendar/CalendarRoute.vue')
// Charts
const Charts = () => import('src/pages/Dashboard/Charts.vue')

let customerMenu = {
  path: '/customer',
  component: DashboardLayout,
  redirect: '/customer/requests/onboarding',
  children: [
    {
      path: 'requests/onboarding',
      name: 'Customer Onboarding Requests',
      component: CustomerOnboardingRequests
    },
    {
      path: 'lists/liquor',
      name: 'Liquor List',
      component: LiquorList
    },
    {
      path: 'new',
      name: 'New Customer',
      component: NewCustomer
    },
    {
      path: 'update',
      name: 'Update Customer',
      component: UpdateCustomer
    },
    {
      path: 'pullingData',
      name: 'Start PullingData',
      component: StartPullingData
    },
    {
      path: 'pos/credentials',
      name: 'Add POS Credentials',
      component: EditPOSCredentials
    }
  ]
}

let venuesMenu = {
  path: '/venues',
  component: DashboardLayout,
  redirect: '/venues/drinks/new',
  children: [
    ,
    {
      path: 'drinks/monitored',
      name: 'monitored drinks',
      component: MonitoredDrinks
    },
    {
      path: 'drinks/unmonitored',
      name: 'unmonitored drinks',
      component: UnmonitoredDrinks
    },
    {
      path: 'drinks/new',
      name: 'new drinks',
      component: NewRecipeList
    }

  ]
}

let spoutsMenu = {
  path: '/spouts',
  component: DashboardLayout,
  redirect: '/spouts/manager',
  children: [
    {
      path: 'manager',
      name: 'Spouts Manager',
      component: SpoutsManager
    },
    {
      path: 'history',
      name: 'Spout History',
      component: SpoutHistory
    }

  ]
}

let tablesMenu = {
  path: '/tables',
  component: DashboardLayout,
  redirect: '/tables/master/drinks',
  children: [
    {
      path: 'master/drinks',
      name: 'Master Drink List',
      component: MasterDrinkList
    },
    {
      path: 'master/liquor',
      name: 'Master Liquor List',
      component: MasterLiquorList
    },
    {
      path: 'master/posSystem',
      name: 'Master POS System Table',
      component: MasterPosSystemTable
    }]
}

let menuMenu = {
  path: '/menu/monitor',
  component: DashboardLayout,
  redirect: '/menu/monitor/items',
  children: [
    {
      path: 'items',
      name: 'Menu Items Monitor',
      component: MenuItemsMonitor
    },
    {
      path: 'pluCode',
      name: 'PLU Code Monitor',
      component: PluCodeMonitor
    }
  ]
}

let posMenu = {
  path: '/posSystems',
  component: DashboardLayout,
  redirect: '/posSystems/dataIngestion/monitor',
  children: [
    {
      path: 'dataIngestion/monitor',
      name: 'Data Ingestion Monitor',
      component: DataIngestionMonitor
    },
    {
      path: 'credentials/manager',
      name: 'POS Credentials Manager',
      component: PosCredentialsManager
    }
    ,
    {
      path: 'manager',
      name: 'POS Systems Manager',
      component: PosSystemsManager
    }
  ]
}

let loginPage = {
  path: '/login',
  name: 'Login',
  component: Login
}

let setPasswordPage = {
  path: '/setPassword',
  name: 'Set Password',
  component: SetPassword
}

let registerPage = {
  path: '/register',
  name: 'Register',
  component: Register
}

let lockPage = {
  path: '/lock',
  name: 'Lock',
  component: Lock
}

let userPage = {
  path: '/user',
  component: DashboardLayout,
  redirect: '/user',
  children: [
      {
        path: '/user',
        name: 'User Page',
        component: User
      }
    ]
}


const routes = [
  {
    path: '/',
    redirect: '/admin/overview'
  },
  customerMenu,
  spoutsMenu,
  tablesMenu,
  menuMenu,
  posMenu,
  venuesMenu,
  loginPage,
  registerPage,
  lockPage,
  userPage,
  setPasswordPage,
  {
    path: '/admin',
    component: DashboardLayout,
    redirect: '/admin/overview',
    children: [
      {
        path: 'overview',
        name: 'Overview',
        component: Overview
      },
      {
        path: 'stats',
        name: 'Stats',
        component: Stats
      },
      {
        path: 'calendar',
        name: 'Calendar',
        component: Calendar
      },
      {
        path: 'charts',
        name: 'Charts',
        component: Charts
      }
    ]
  },
  {path: '*', component: NotFound}
]

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkActiveClass: 'active'
})

/*
  - The function is triggered everytime the user navigates the UI pages, before going to the navigated page.
  - Used here for the login session purpose.
*/
router.beforeEach((to, from, next) => {
  // check if logged in first
  let isAuthenticated = (Vue.$cookies.get("Exp") - Date.now() <= 0);
  if(to.name == 'Set Password') next()
  else if (to.name !== 'Login' && isAuthenticated) {
    Vue.$cookies.set("Exp", 0)
    Vue.$cookies.set("Token", "")
    Vue.$cookies.set('Email', "");
    Vue.$cookies.set('Password', "");
    next({ name: 'Login' })
  }
  else next()
})

export default router
