<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications></notifications>
    <side-bar>
      <!--This is the menue with the  user profile quick access button-->
      <user-menu></user-menu>
      <mobile-menu></mobile-menu>
      <template slot-scope="props" slot="links">
        <sidebar-item
          :link="{
            name: 'Dashboard',
            icon: 'nc-icon nc-align-left-2',
            path: '/admin/overview'
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Customer',
            icon: 'nc-icon nc-single-02',
            notificationCount: newRequestsNotification
          }"
        >
          <sidebar-item
            :link="{
              name: 'Customers Requests ',
              path: '/customer/requests/onboarding',
              notificationCount: newRequestsNotification
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: 'New Customer',
              path: '/customer/new'
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: 'Add POS Credentials',
              path: '/customer/pos/credentials'
            }"
          ></sidebar-item>
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Venues',
            icon: 'nc-icon nc-paper-2',
            notificationCount: newDrinksNotification
          }"
        >
          <sidebar-item
            :link="{
              name: 'New Drinks',
              path: '/venues/drinks/new',
              notificationCount: newDrinksNotification
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: 'Monitored Drinks',
              path: '/venues/drinks/monitored'
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: 'Un-monitored Drinks',
              path: '/venues/drinks/unmonitored'
            }"
          ></sidebar-item>
        </sidebar-item>
        <sidebar-item
          :link="{ name: 'POS Systems', icon: 'nc-icon nc-grid-45' }"
        >
          <sidebar-item
            :link="{
              name: 'Monitor Data Ingestion',
              path: '/posSystems/dataIngestion/monitor'
            }"
          ></sidebar-item>
        </sidebar-item>
      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content @click.native="toggleSidebar"> </dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./Extra/MobileMenu.vue";
import UserMenu from "./Extra/UserMenu.vue";
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import { BBadge } from "bootstrap-vue";
import { initAPI } from "src/gateway/api-instance.js";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu,
    UserMenu,
    BBadge
  },
  data() {
    return {
      newDrinksNotification: 0,
      newRequestsNotification: 0
    };
  },
  computed: {
    // This is a computed variable, that it's value is recomputed every time it's used throughout the component.
    Instance() {
      return initAPI(this.$cookies.get("Token"), process.env.VUE_APP_API_URL);
    }
  },
  methods: {
    /*
      - Calls a GET API, to retrieve the number of new drinks added to the database.
    */
    getDrinksNotifications() {
      this.Instance.get("simplica/new_drinks/count").then(response => {
        this.newDrinksNotification = response.data;
      });
    },
    /*
      - Calls a GET API, to retrieve the number of new installation requests recieved from shift4.
    */
    getRequestsNotifications() {
      this.Instance.get("webhooks/shift4/requests/installations/new").then(
        response => {
          this.newRequestsNotification = response.data;
        }
      );
    },
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    initScrollbar() {
      let docClasses = document.body.classList;
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        // if we are on windows OS we activate the perfectScrollbar function
        initScrollbar("main-panel");

        docClasses.add("perfect-scrollbar-on");
      } else {
        docClasses.add("perfect-scrollbar-off");
      }
    }
  },
  mounted() {
    this.initScrollbar();
    let vm = this;
    // When a session starts if the token is not empty.
    if (vm.$cookies.get("Token") != "") {
      // we set reoccuring function call with a specific interval, to insure that the notifications count if frequently updated.
      let myvar = setInterval(this.getDrinksNotifications, 100000);
      this.getDrinksNotifications();

      myvar = setInterval(this.getRequestsNotifications, 100000);
      this.getRequestsNotifications();
    }
  }
};
</script>
