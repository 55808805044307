<template>
  <auth-layout pageClass="login-page">
    <div class="row d-flex justify-content-center align-items-center">
      <div class="col-lg-4 col-md-6 col-sm-8">
        <ValidationObserver v-slot="{ handleSubmit }">
          <!--You can specify transitions on initial render. The `card-hidden` class will be present initially and then it will be removed-->
          <form @submit.prevent="handleSubmit(submit)">
            <fade-render-transition>
              <card>
                <div slot="header">
                  <h3 class="card-title text-center">Login</h3>
                </div>
                <div>
                  <ValidationProvider
                    name="email"
                    rules="required|email"
                    v-slot="{ passed, failed }"
                  >
                    <fg-input
                      type="email"
                      :error="
                        failed
                          ? email === null
                            ? 'Unrecognised email address or password'
                            : 'The Password field is required'
                          : null
                      "
                      :hasSuccess="passed"
                      label="Email address"
                      name="email"
                      v-model="email"
                    >
                    </fg-input>
                  </ValidationProvider>
                  <ValidationProvider
                    name="password"
                    rules="required|min:5"
                    v-slot="{ passed, failed }"
                  >
                    <fg-input
                      type="password"
                      :error="
                        failed
                          ? password === null
                            ? 'Unrecognised email address or password'
                            : 'The Password field is required'
                          : null
                      "
                      :hasSuccess="passed"
                      name="password"
                      label="Password"
                      v-model="password"
                    >
                    </fg-input>
                  </ValidationProvider>
                  <fg-input>
                    <l-checkbox v-model="subscribe">
                      Subscribe to newsletter
                    </l-checkbox>
                  </fg-input>
                </div>
                <div class="text-center">
                  <button
                    type="submit"
                    class="btn btn-fill btn-info btn-round btn-wd "
                  >
                    Login
                  </button>
                  <br />
                  <div class="forgot">
                    <router-link to="/register" class="card-category">
                      Forgot your password?
                    </router-link>
                  </div>
                </div>
              </card>
            </fade-render-transition>
          </form>
        </ValidationObserver>
        <!--<button type="primary" class="btn btn-fill btn-info btn-round btn-wd " v-on:click="testSubmit">test</button>-->
      </div>
    </div>
  </auth-layout>
</template>
<script>
import {
  Checkbox as LCheckbox,
  FadeRenderTransition
} from "src/components/index";
import AuthLayout from "./AuthLayout.vue";
import { extend } from "vee-validate";
import { required, email, min } from "vee-validate/dist/rules";
import { initAPI } from "src/gateway/api-instance.js";

extend("email", email);
extend("required", required);
extend("min", min);

export default {
  components: {
    LCheckbox,
    FadeRenderTransition,
    AuthLayout
  },
  data() {
    return {
      email: "",
      password: "",
      subscribe: true
    };
  },
  computed: {
    // This is a computed variable, that it's value is recomputed every time it's used throughout the component.
    Instance() {
      return initAPI(this.$cookies.get("Token"), process.env.VUE_APP_API_URL);
    }
  },
  methods: {
    /*
      - Function invoked when the login button is clicked.
      - The function is responsible for compeleting the login process for the app users.
      - A post API to the backend is called with an object containing the email and password entered for validation.
    */
    submit() {
      var obj = {
        email: this.email,
        password: this.password
      };
      var vm = this;
      this.Instance.post("signin", obj)
        .then(function(response) {
          /*   
          - If the API response is 200-OK:
            - The session coockies are filled with:
              - Session token.
              - Session expiration date.
              - User name (first + last name).
              - user email.
              - user password.
            - The user is then redirected to the home page.
          */
          vm.$cookies.set("Token", response.data.Token);
          vm.$cookies.set("Exp", response.data.Exp);
          vm.$cookies.set(
            "Name",
            response.data.first_name + " " + response.data.last_name
          );
          vm.$cookies.set("Username", response.data.username);
          vm.$cookies.set("Email", obj.email);
          vm.$cookies.set("Password", obj.password);
          vm.$router.push("/admin/overview");
        })
        .catch(function(error) {
          /*   
          - If the API response comes with an error:
            - The session cookies are rest.
            - the emial and password variables are set to null, to invoke the validation error messages.
          */
          vm.password = null;
          vm.email = null;
          vm.$cookies.set("Exp", 0);
          vm.$cookies.set("Token", "");
          vm.$cookies.set("Name", "");
          vm.$cookies.set("Email", "");
          vm.$cookies.set("Password", "");
          vm.$cookies.set("Username", "");
          console.error(error);
        });
    },
    toggleNavbar() {
      document.body.classList.toggle("nav-open");
    },
    closeMenu() {
      document.body.classList.remove("nav-open");
      document.body.classList.remove("off-canvas-sidebar");
    }
  },
  beforeDestroy() {
    this.closeMenu();
  }
};
</script>
<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
</style>
