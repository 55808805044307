var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card',[_c('div',{attrs:{"id":"app"}},[_c('form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.updateProfile.apply(null, arguments)}}},[_c('div',{staticClass:"form-group"},[_c('fg-input',{class:{
            'has-success': this.submitted && !_vm.$v.user.NewPassword.$error
          },attrs:{"type":"password","label":"New Password","placeholder":"Enter Password","required":""},model:{value:(_vm.NewPassword),callback:function ($$v) {_vm.NewPassword=$$v},expression:"NewPassword"}}),(this.submitted && _vm.$v.user.NewPassword.$error)?_c('div',[(!_vm.$v.user.NewPassword.required)?_c('span',{staticStyle:{"color":"red","font-size":"0.8em"}},[_vm._v("Password is required")]):_vm._e(),(_vm.NewPassword && !_vm.$v.user.NewPassword.valid)?_c('span',{staticStyle:{"color":"red","font-size":"0.8em"}},[_vm._v("Password contains atleast One Uppercase, One Lowercase, One Number and One Special Chacter")]):_vm._e(),(
              _vm.NewPassword &&
                _vm.$v.user.NewPassword.valid &&
                !_vm.$v.user.NewPassword.minLength
            )?_c('span',{staticStyle:{"color":"red","font-size":"0.8em"}},[_vm._v(" Password must be minimum 9 characters")]):_vm._e(),(_vm.NewPassword && !_vm.$v.user.NewPassword.maxLength)?_c('span',{staticStyle:{"color":"red","font-size":"0.8em"}},[_vm._v("Password must be maximum 19 characters")]):_vm._e()]):_vm._e()],1),_c('div',{staticClass:"form-group"},[_c('fg-input',{class:{
            'has-success': this.submitted && !_vm.$v.user.confirmPassword.$error
          },attrs:{"type":"password","label":"Confirm New Password","placeholder":"Re-type New Password","required":""},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}}),(this.submitted && _vm.$v.user.confirmPassword.$error)?_c('div',[(!_vm.$v.user.confirmPassword.required)?_c('span',{staticStyle:{"color":"red","font-size":"0.8em"}},[_vm._v(" Confirm Password is required ")]):_vm._e(),(_vm.confirmPassword && !_vm.$v.user.confirmPassword.sameAsPassword)?_c('span',{staticStyle:{"color":"red","font-size":"0.8em"}},[_vm._v("Password and Confirm Password should match")]):_vm._e()]):_vm._e()],1),_c('div',{staticClass:"text-right"},[_c('button',{staticClass:"btn btn-info btn-fill btn-wd",attrs:{"type":"submit","disabled":_vm.isDisabled},on:{"click":function($event){$event.preventDefault();return _vm.updatePassword.apply(null, arguments)}}},[_vm._v(" Update Password ")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }