<template>
  <card>
    <div slot="header">
      <h4 class="title">Edit Profile</h4>
    </div>
    <div>
      <form @submit.prevent="validateBeforeSubmit" novalidate>
        <div class="row">
          <div class="col-md-6">
            <fg-input
              type="text"
              label="First Name"
              placeholder="First Name"
              v-model="user.first_name"
            >
            </fg-input>
          </div>
          <div class="col-md-6">
            <fg-input
              type="text"
              label="Last Name"
              placeholder="Last Name"
              v-model="user.last_name"
            >
            </fg-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-5" :class="[isEmailValid()]">
            <fg-input
              name="email"
              required
              type="email"
              label="Email"
              placeholder="User Email"
              v-model="user.email"
            >
            </fg-input>
          </div>
          <div class="col-md-3">
            <fg-input
              type="text"
              label="Username"
              placeholder="Username"
              v-model="user.user_name"
            >
            </fg-input>
          </div>
          <div class="col-md-4">
            <fg-input label="Phone Number" id="fginput">
              <vue-tel-input
                id="new_number"
                autocomplete="on"
                v-model="user.phone"
                mode="international"
                :autoDefaultCountry="false"
              >
              </vue-tel-input>
            </fg-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <fg-input
              type="text"
              label="Address"
              placeholder="Home Address"
              v-model="user.address"
            >
            </fg-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <fg-input
              type="text"
              label="City"
              placeholder="City"
              v-model="user.city"
            >
            </fg-input>
          </div>
          <div class="col-md-4">
            <fg-input
              type="text"
              label="Country"
              placeholder="Country"
              v-model="user.country"
            >
            </fg-input>
          </div>
          <div class="col-md-4">
            <fg-input
              label="Postal Code"
              placeholder="ZIP Code"
              v-model="user.zip_code"
            >
            </fg-input>
          </div>
        </div>

        <div class="text-right">
          <button
            type="submit"
            class="btn btn-info btn-fill btn-wd"
            @click.prevent="createUser"
            :disabled="!validEmail"
          >
            Create User
          </button>
        </div>
        <div class="clearfix"></div>
      </form>
    </div>
  </card>
</template>

<script>
import Vue from "vue";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import Card from "../../../../components/Cards/Card.vue";
import { initAPI } from "src/gateway/api-instance.js";
import LAlert from "src/components/Alert";
import NotificationSample_UserSuccess from "src/pages/Dashboard/Components/NotificationSample_UserSuccess.vue";
import NotificationSample_UserFail from "src/pages/Dashboard/Components/NotificationSample_UserFail.vue";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";

extend("required", required);
Vue.use(VueTelInput);

export default {
  name: "password",
  components: { Card, LAlert },
  data() {
    return {
      user: {
        first_name: "",
        last_name: "",
        user_name: "",
        email: "",
        password: "ChangeThis1!",
        address: "",
        city: "",
        country: "",
        zip_code: "",
        phone: "",
        notifications: Array
      },
      validEmail: false,
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
    };
  },
  computed: {
    // This is a computed variable, that it's value is recomputed every time it's used throughout the component.
    Instance() {
      return initAPI(this.$cookies.get("Token"), process.env.VUE_APP_API_URL);
    }
  },
  mounted() {},
  methods: {
    /*
      - Called when the user, clicks the "Create User" button in the UI.
      - Calls the signup API in the backend to start signing this user up for the app.
    */
    createUser() {
      let vm = this;
      this.Instance.post("signup", this.user)
        .then(function(response) {
          // reset the user object to clear the UI componenets.
          vm.user = {
            first_name: "",
            last_name: "",
            user_name: "",
            email: "",
            password: "ChangeThis1!",
            address: "",
            city: "",
            country: "",
            zip_code: "",
            phone: "",
            notifications: Array
          };
          // create a popup notification to inform the user that the signup process has been compeleted successfully.
          vm.notifyVue(
            "top",
            "right",
            NotificationSample_UserSuccess,
            "success"
          );
        })
        .catch(function(error) {
          console.error(error);
          // create a popup notification to inform the user that the signup process has failed.
          vm.notifyVue("top", "right", NotificationSample_UserFail, "danger");
        });
    },
    /*
      - This value set function is called everytime a change is made in the component that it's tag class contains this value.
      - Using the regex string declared and intialized in the data clause, it tests the user email against it.
      - Returns class "has-success" or "has-error", based on the test result.
      - Disables/enables the "Create user" button by setting the value of the validEmail variable.
    */
    isEmailValid: function() {
      let ans =
        this.user.email == ""
          ? ""
          : this.reg.test(this.user.email)
          ? "has-success"
          : "has-error";

      this.validEmail = ans == "has-success" ? true : false;

      return ans;
    },
    validateBeforeSubmit() {
      this.$refs.form
        .validate()
        .then(function(response) {
          // Validation success if response === true
        })
        .catch(function(e) {
          console.error(e);
        });
    },
    /*
      - Parameters:
        - verticalAlign: where the pop-up notification would show on the vertical acess of the window (top, bottom).
        - horizontalAlign: where the pop-up notification would show on the horizontal acess of the window (left, right).
        - component: the pop-up notification component that will be rendered.
        - type: The type of the notification, decides it's color (success, danger, warning, info).
    */
    notifyVue(verticalAlign, horizontalAlign, component, type) {
      this.$notify({
        component: component,
        icon: "nc-icon nc-app",
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: type
      });
    }
  }
};
</script>
<style scoped>
.container >>> .invalid-feedback {
  color: red;
}
.container >>> .form-group {
  margin: 10px;
}
.input-has-value-style {
  border: 2px solid green;
  background-color: lightgreen;
}

.input-no-value-style {
  border: 2px solid red;
  background-color: pink;
}
</style>
