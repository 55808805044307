<template>
  <card>
    <div slot="header">
      <h4 class="title">Edit Profile</h4>
    </div>
    <div>
      <form>
        <div class="row">
          <div class="col-md-6">
            <fg-input
              type="text"
              label="First Name"
              placeholder="First Name"
              v-model="user.first_name"
              v-on:keydown="captureKeystroke"
            >
            </fg-input>
          </div>
          <div class="col-md-6">
            <fg-input
              type="text"
              label="Last Name"
              placeholder="Last Name"
              v-model="user.last_name"
              v-on:keydown="captureKeystroke"
            >
            </fg-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-5">
            <fg-input
              type="text"
              label="Email"
              :disabled="true"
              placeholder="Paper dashboard"
              v-model="user.email"
            >
            </fg-input>
          </div>
          <div class="col-md-3">
            <fg-input
              type="text"
              label="Username"
              placeholder="Username"
              v-model="user.user_name"
              v-on:keydown="captureKeystroke"
            >
            </fg-input>
          </div>
          <div class="col-md-4">
            <fg-input
              label="Phone Number"
              id="old_fginput"
              v-on:keydown="captureKeystroke"
            >
              <vue-tel-input
                id="old_num"
                autocomplete="on"
                v-model="user.phone"
                mode="international"
                :autoDefaultCountry="false"
                @enter="captureKeystroke"
              >
              </vue-tel-input>
            </fg-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <fg-input
              type="text"
              label="Address"
              placeholder="Home Address"
              v-model="user.address"
              v-on:keydown="captureKeystroke"
            >
            </fg-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <fg-input
              type="text"
              label="City"
              placeholder="City"
              v-model="user.city"
              v-on:keydown="captureKeystroke"
            >
            </fg-input>
          </div>
          <div class="col-md-4">
            <fg-input
              type="text"
              label="Country"
              placeholder="Country"
              v-model="user.country"
              v-on:keydown="captureKeystroke"
            >
            </fg-input>
          </div>
          <div class="col-md-4">
            <fg-input
              label="Postal Code"
              placeholder="ZIP Code"
              v-model="user.zip_code"
              v-on:keydown="captureKeystroke"
            >
            </fg-input>
          </div>
        </div>

        <password-card :user="user" />

        <div class="text-right">
          <button
            type="button"
            class="btn btn-info btn-fill btn-wd"
            v-on:click="updateProfile"
            :disabled="!changeDetected"
          >
            Update Profile
          </button>
        </div>
        <div class="clearfix"></div>
      </form>
    </div>
  </card>
</template>

<script>
import Vue from "vue";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import Card from "../../../../components/Cards/Card.vue";
import passwordCard from "./PasswordCard.vue";
import { initAPI } from "src/gateway/api-instance.js";

Vue.use(VueTelInput);

export default {
  name: "password",
  components: { Card, passwordCard },
  data() {
    return {
      keystrokeDetected: false,
      changeDetected: false
    };
  },
  props: {
    user: {
      Type: Object,
      default: () => {
        return {
          first_name: "michael23",
          last_name: "michael23",
          user_name: "michael23",
          email: "",
          password: "",
          address: "Melbourne, Australia",
          city: "melbourne",
          country: "",
          zip_code: "",
          phone: "1234567"
        };
      }
    }
  },
  computed: {
    // This is a computed variable, that it's value is recomputed every time it's used throughout the component.
    Instance() {
      return initAPI(this.$cookies.get("Token"), process.env.VUE_APP_API_URL);
    }
  },
  methods: {
    /*
      - Called when the "Update Profile" button is clicked.
      - If changes has been made in the form, the update POST API, is called with the updated user object.
    */
    updateProfile() {
      if (this.changeDetected) {
        let vm = this;
        this.Instance.post("update/user/info", this.user)
          .then(function(response) {
            vm.changeDetected = false;
            console.log(response);
          })
          .catch(function(error) {
            console.error(error);
          });
      }
    },
    /*
      - The function capture, key strokes to capture any possible changes made in the form to the user general info.
      - If any key is pressed inside any of the input text boxes, this function is called.
    */
    captureKeystroke(keyEvent) {
      this.changeDetected = true;
    }
  }
};
</script>
<style scoped>
.container >>> .invalid-feedback {
  color: red;
}
.container >>> .form-group {
  margin: 10px;
}
.input-has-value-style {
  border: 2px solid green;
  background-color: lightgreen;
}

.input-no-value-style {
  border: 2px solid red;
  background-color: pink;
}
</style>
