<template>
  <div class="card">
    <div class="card-image" v-if="$slots.image">
      <slot name="image"></slot>
    </div>
    <div class="card-header" v-if="$slots.header || title">
      <header>
        
        <button v-if="withClosebtn" type="button" class="close" aria-label="Close" v-on:click="closeFunction()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="card-title">{{title}}</h4>
        <p class="card-category">{{subTitle}}</p>
      </header>
      </slot>
    </div>
    <div class="card-body">
      <slot></slot>
    </div>
    <div class="card-footer" v-if="$slots.footer">
      <hr>
      <slot name="footer"></slot>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'card',
    props: {
      title: {
        type: String,
        default: ''
      },
      subTitle: String,
      withClosebtn: false,
      closeFunction: {
        type: Function,
        default: () => {}
      },
    }
  }
</script>
<style>
.custom-button {
  font-family: Arial;
  float: right;
  font-size: 1.005rem;
  text-align: right;
  margin: 0.005px;
  cursor: pointer;
}
.custom-icon {
  font-size: 1rem;
  font-weight: bold;
  text-align: right;
  margin: 1;
}
</style>
