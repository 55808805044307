<template>
  <card>
    <div id="app">
      <form @submit.prevent="updateProfile" novalidate>
        <div class="form-group">
          <fg-input
            type="password"
            :class="{
              'has-success': this.submitted && !$v.user.NewPassword.$error
            }"
            label="New Password"
            placeholder="Enter Password"
            v-model="NewPassword"
            required
          />

          <!-- Error tags for the update password feature to insure that the new password adhere to the strong password conditions -->
          <div v-if="this.submitted && $v.user.NewPassword.$error">
            <span
              v-if="!$v.user.NewPassword.required"
              style="color:red; font-size: 0.8em;"
              >Password is required</span
            >
            <span
              v-if="NewPassword && !$v.user.NewPassword.valid"
              style="color:red; font-size: 0.8em"
              >Password contains atleast One Uppercase, One Lowercase, One
              Number and One Special Chacter</span
            >
            <span
              v-if="
                NewPassword &&
                  $v.user.NewPassword.valid &&
                  !$v.user.NewPassword.minLength
              "
              style="color:red; font-size: 0.8em"
            >
              Password must be minimum 9 characters</span
            >
            <span
              v-if="NewPassword && !$v.user.NewPassword.maxLength"
              style="color:red; font-size: 0.8em"
              >Password must be maximum 19 characters</span
            >
          </div>
        </div>
        <div class="form-group">
          <fg-input
            type="password"
            :class="{
              'has-success': this.submitted && !$v.user.confirmPassword.$error
            }"
            label="Confirm New Password"
            placeholder="Re-type New Password"
            v-model="confirmPassword"
            required
          />

          <!-- Error tags for the update password feature to insure that the password and confirm password match -->
          <div v-if="this.submitted && $v.user.confirmPassword.$error">
            <span
              v-if="!$v.user.confirmPassword.required"
              style="color:red; font-size: 0.8em"
            >
              Confirm Password is required
            </span>
            <span
              v-if="confirmPassword && !$v.user.confirmPassword.sameAsPassword"
              style="color:red; font-size: 0.8em"
              >Password and Confirm Password should match</span
            >
          </div>
        </div>
        <div class="text-right">
          <button
            type="submit"
            class="btn btn-info btn-fill btn-wd"
            @click.prevent="updatePassword"
            :disabled="isDisabled"
          >
            Update Password
          </button>
        </div>
      </form>
    </div>
  </card>
</template>
<script>
import Vue from "vue";
import Card from "../../../../components/Cards/Card.vue";
import {
  required,
  minLength,
  maxLength,
  sameAs
} from "vuelidate/lib/validators";
import { initAPI } from "src/gateway/api-instance.js";

export default {
  name: "password",
  components: { Card },

  data() {
    return {
      NewPassword: "",
      confirmPassword: "",
      submitted: false
    };
  },
  props: {
    user: {
      Type: Object,
      default: () => {
        return {
          first_name: "michael23",
          last_name: "michael23",
          user_name: "michael23",
          email: "",
          password: "",
          address: "Melbourne, Australia",
          city: "melbourne",
          country: "",
          zip_code: "",
          phone: "1234567"
        };
      }
    }
  },
  // The vuelidate library, enables the functionality of custom validations on the component's data values.
  validations: {
    // We want to validate a value inside the userr object, so we create a validation key for the parent object first
    user: {
      // Then specify the keys of the values we want to validate inside.
      NewPassword: {
        required,
        /*
          Valid function:
            - Takes a password value passed from the umput textbox tag with the entered password.
            - returns true if the value adheres to the strong password rules:
              - Contains at least 1 uppercase charachter.
              - Contains at least 1 lowercase charachter.
              - Contains at least 1 special charachter.
              - Contains at least 1 number.
        */
        valid: function(password) {
          const containsUppercase = /[A-Z]/.test(password);
          const containsLowercase = /[a-z]/.test(password);
          const containsNumber = /[0-9]/.test(password);
          const containsSpecial = /[#?!@$%^&*-]/.test(password);
          return (
            containsUppercase &&
            containsLowercase &&
            containsNumber &&
            containsSpecial
          );
        },
        minLength: minLength(9),
        maxLength: maxLength(19)
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs("NewPassword")
      }
    }
  },
  mounted() {
    /* 
      when the component is first mounted, we intialize the submited variable with true and activate the validations clause,
       for the password validations
    */
    this.submitted = true;
    return this.$v.$touch();
  },
  computed: {
    isDisabled() {
      return this.$v.$invalid;
    },
    Instance() {
      // This is a computed variable, that it's value is recomputed every time it's used throughout the component.
      return initAPI(this.$cookies.get("Token"), process.env.VUE_APP_API_URL);
    }
  },
  methods: {
    /*
      - Called when the user, clicks the "update password" button in the UI.
      - Invokes the validation functions to validate the updated password.
      - Calls the password reset API in the backend to update the user's password.
    */
    updatePassword() {
      // The submitted variable, shows the error messages when the user clicks on the "update password" button.
      this.submitted = true;
      // Invoke the validation process.
      this.$v.$touch();

      if (this.$v.$invalid) {
        return false; // stop here if form is invalid
      } else {
        // updated the user password with the new validated one.
        user.password = user.NewPassword;

        // reset password in backend
        var obj = JSON.stringify({
          email: this.user.email,
          password: this.user.password
        });

        this.Instance.post("password/reset/", obj)
          .then(function(response) {
            console.log(response);
          })
          .catch(function(error) {
            console.error(error);
          });
      }
    }
  }
};
</script>
<style scoped>
.container >>> .invalid-feedback {
  color: red;
}
.container >>> .form-group {
  margin: 10px;
}
.input-has-value-style {
  border: 2px solid green;
  background-color: lightgreen;
}

.input-no-value-style {
  border: 2px solid red;
  background-color: pink;
}
</style>
