var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('notifications'),_c('side-bar',{scopedSlots:_vm._u([{key:"links",fn:function(props){return [_c('sidebar-item',{attrs:{"link":{
          name: 'Dashboard',
          icon: 'nc-icon nc-align-left-2',
          path: '/admin/overview'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Customer',
          icon: 'nc-icon nc-single-02',
          notificationCount: _vm.newRequestsNotification
        }}},[_c('sidebar-item',{attrs:{"link":{
            name: 'Customers Requests ',
            path: '/customer/requests/onboarding',
            notificationCount: _vm.newRequestsNotification
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'New Customer',
            path: '/customer/new'
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Add POS Credentials',
            path: '/customer/pos/credentials'
          }}})],1),_c('sidebar-item',{attrs:{"link":{
          name: 'Venues',
          icon: 'nc-icon nc-paper-2',
          notificationCount: _vm.newDrinksNotification
        }}},[_c('sidebar-item',{attrs:{"link":{
            name: 'New Drinks',
            path: '/venues/drinks/new',
            notificationCount: _vm.newDrinksNotification
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Monitored Drinks',
            path: '/venues/drinks/monitored'
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Un-monitored Drinks',
            path: '/venues/drinks/unmonitored'
          }}})],1),_c('sidebar-item',{attrs:{"link":{ name: 'POS Systems', icon: 'nc-icon nc-grid-45' }}},[_c('sidebar-item',{attrs:{"link":{
            name: 'Monitor Data Ingestion',
            path: '/posSystems/dataIngestion/monitor'
          }}})],1)]}}])},[_c('user-menu'),_c('mobile-menu')],1),_c('div',{staticClass:"main-panel"},[_c('top-navbar'),_c('dashboard-content',{nativeOn:{"click":function($event){return _vm.toggleSidebar.apply(null, arguments)}}}),_c('content-footer')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }