<template>
  <card>
    <br />
    <div>
      <h5>Chose which notifications you'd like to recieve mails about:</h5>
    </div>
    <script
      type="application/javascript"
      src="https://cdnjs.cloudflare.com/ajax/libs/vue/2.5.17/vue.js"
    ></script>
    <link
      href="https://unpkg.com/bootstrap@4.5.2/dist/css/bootstrap.min.css"
      rel="stylesheet"
    />
    <link
      href="https://unpkg.com/bootstrap-vue@2.16.0/dist/bootstrap-vue.css"
      rel="stylesheet"
    />
    <script
      type="application/javascript"
      rc="https://unpkg.com/bootstrap-vue@2.16.0/dist/bootstrap-vue.js"
    ></script>
    <div>
      <!--we loop through the list of all the notification types in the database-->
      <div
        class="v-input__slot"
        v-for="(notification, id) in notifications"
        v-bind:key="id"
      >
        <!--
        - For every notification type create a checkbox. 
        - The v-model name is the same as the notification type but it's seperated with '_' instead of spaces.
        - The notification type description is split with spaces then joined with '_' to get the proper v-model for the current checkbox.
      -->
        <b-form-checkbox
          switch
          size="lg"
          v-model="
            user.notifications[notification.description.split(' ').join('_')]
          "
        >
          {{ notification.description }}
        </b-form-checkbox>
      </div>
      <br />
      <div class="text-right">
        <button
          type="submit"
          class="btn btn-info btn-fill btn-wd"
          @click.prevent="updateSubscriptions"
        >
          Update Subscriptions
        </button>
      </div>
      <div class="clearfix"></div>
    </div>
  </card>
</template>
<script>
import Vue from "vue";
import { BFormCheckboxGroup, BFormCheckbox } from "bootstrap-vue";
import Card from "../../../../components/Cards/Card.vue";
import { initAPI } from "src/gateway/api-instance.js";

Vue.component("b-form-checkbox-group", BFormCheckboxGroup);
Vue.component("b-form-checkbox", BFormCheckbox);

export default {
  name: "notifications",
  components: { Card },
  data() {
    return {
      notifications: [],
      user: {}
    };
  },
  computed: {
    // This is a computed variable, that it's value is recomputed every time it's used throughout the component.
    Instance() {
      return initAPI(this.$cookies.get("Token"), process.env.VUE_APP_API_URL);
    }
  },
  props: {},
  methods: {
    /*
      - Calls a GET API to get a list of notifications types available in the database,
        for the dynamic subscribtion list.
    */
    getAllNotification() {
      let vm = this;
      this.Instance.get("/notifications")
        .then(function(response) {
          vm.notifications = response.data;
          // after getting the list of notifications, get the user's list of subscribed notifications.
          vm.getUserNotification();
        })
        .catch(function(error) {
          console.error(error);
        });
    },
    /*
      - Calls a GET API to get a list of the notifications the user is subscrribed to.
      - The API returns an object with the user email and an object object with all the notifications
        with each one holding the value of true or false (subscribed or not).
    */
    getUserNotification() {
      let vm = this;
      this.Instance.get("/user/notifications?email=" + vm.$cookies.get("Email"))
        .then(function(response) {
          vm.user = response.data;
        })
        .catch(function(error) {
          console.error(error);
        });
    },
    /*
      - Calls a POSt API, to update the user subscribtions in the database.
      - The API takes an object with the user email and an object with all the notifications
        with each one holding the value of true or false (subscribed or not).
    */
    updateSubscriptions() {
      this.Instance.post("update/user/notifications", this.user)
        .then(function(response) {
          console.log(response);
        })
        .catch(function(error) {
          console.error(error);
        });
    }
  },
  mounted() {
    this.getAllNotification();
  }
};
</script>
<style>
.v-input__slot {
  position: relative;
  transform: translateX(8%) translateY(5%);
}
</style>
