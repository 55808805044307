<template>
  <auth-layout pageClass="lock-page">
    <div class="content">
      <form method="#" action="#">
        <div class="user-profile">
          <div class="author">
            <img class="avatar" src="static/img/login-logo.png" alt="...">
          </div>
          <h4>{{title}}</h4>
          <fg-input type="password" placeholder="Enter Password" v-model="password"></fg-input>
          <button type="button" class="btn btn-round btn-info btn-wd" v-on:click="unlock">Unlock</button>
        </div>
      </form>
    </div>
  </auth-layout>
</template>
<script>
  import AuthLayout from './AuthLayout.vue'
  export default {
    components: {
      AuthLayout
    },
    props: {
    },
    computed: {
      title(){
        var vm = this;
        return vm.$cookies.get('Username')
      }
    },
    data () {
      return {
        password: ''
      }
    },
    methods: {
      unlock(){
        var vm = this;
        if(this.password == vm.$cookies.get("Password")){
          vm.$router.push("/");
        }
      },
      toggleNavbar () {
        document.body.classList.toggle('nav-open')
      },
      closeMenu () {
        document.body.classList.remove('nav-open')
        document.body.classList.remove('off-canvas-sidebar')
      }
    },
    beforeDestroy () {
      this.closeMenu()
    }
  }
</script>
<style>
</style>
