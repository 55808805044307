<template>
  <card>
    <link
      rel="stylesheet"
      href="https://fonts.googleapis.com/css?family=Noto+Sans+HK&amp;display=swap"
    />
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/normalize/5.0.0/normalize.min.css"
    />
    <div class="row d-flex">
      <div class="col-md-10">
        <fg-input name="Venue" label="Venue">
          <el-select
            class="select-primary"
            placeholder="assign venue"
            v-model="venue"
            @change="assignVenue"
            :disabled="user == ''"
            filterable
          >
            <el-option
              class="select-primary"
              v-for="item in venues"
              :key="item.ID"
              :label="item.NAME"
              :value="item.ID"
            >
            </el-option>
          </el-select>
        </fg-input>
      </div>

      <div class="col-md-10">
        <ul class="todo-list">
          <li v-for="venue in user.venues" class="todo" :key="venue">
            <div class="view">
              <input class="toggle" type="checkbox" checked="true" />
              <label>
                {{ allvenues[allvenues.findIndex(p => p.ID.toString() == venue)].NAME }}
              </label>
              <button class="destroy" @click="removeVenue(venue)"></button>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <br />
    <div class="text-right">
      <button
        type="submit"
        class="btn btn-info btn-fill btn-wd"
        @click="updateVenues"
      >
        Assign Venues
      </button>
    </div>
    <div class="clearfix"></div>
  </card>
</template>

<script>
import { Select, Option } from "element-ui";
import Card from "../../../../components/Cards/Card.vue";
import { initAPI } from "src/gateway/api-instance.js";

export default {
  name: "VenuesList",
  components: { Card, [Select.name]: Select, [Option.name]: Option },
  data() {
    return {
      venue: "",
      allvenues: [],
      assignedVenues: [],
      venues: []
    };
  },
  computed: {
    // This is a computed variable, that it's value is recomputed every time it's used throughout the component.
    Instance() {
      return initAPI(this.$cookies.get("Token"), process.env.VUE_APP_API_URL);
    }
  },
  props: {
    user: {
      Type: Object,
      default: () => {
        return {
          venues: []
        };
      }
    }
  },
  watch: {
  user: {
      handler() {
        console.log("user, changed");
        var vm = this;
        this.user.venues = this.user.venues.map(String)
        this.venues = this.allvenues.filter(function(obj){if(vm.user.venues.indexOf(obj.ID) === -1) return obj;});
        this.assignedVenues = this.allvenues.filter(function(obj){if(vm.user.venues.indexOf(obj.ID) !== -1) return obj;});
      },
    }
  },
  methods: {
    /*
      - Calls a GET API to get a list of venues in the database.
    */
    getVenues() {
      let vm = this;
      this.Instance.get("simplica/venues")
        .then(function(response) {
          vm.allvenues = response.data
          vm.venues = response.data
        })
        .catch(function(error) {
          console.error(error);
        });
    },
    /*
      - Called by the, @change attribute in the el-select tag.
      - venueID: the ID of the selected venue in the UI.
      - Adds that Id to the list of venues for the specified user, sent in the props.
      - Removes the venue from the dropdown list to prevent duplicates.
    */
    assignVenue(venueID) {
      if(this.user.venues.indexOf(venueID) === -1){
        this.user.venues.push(venueID);
        var vm = this;
        let assignedVenue = this.allvenues.filter(function(obj){if(obj.ID == venueID) return obj;});
        this.assignedVenues.push(assignedVenue)
        this.venues = this.allvenues.filter(function(obj){if(vm.user.venues.indexOf(obj.ID) === -1) return obj;});
      }
    },
    /*
      - Called by the, destroy button in the list block.
      - venueID: the ID of the venue, which it's destroy button was clicked in the UI.
      - removes that Id from the list of venues for the specified user, sent in the props.
      - adds the removed venue back to the dropdown list, again.
    */
    removeVenue(venueID) {
      this.user.venues.splice(this.user.venues.indexOf(venueID), 1);
      let removedVenue = this.allvenues.filter(function(obj){if(obj.ID == venueID) return obj;});
      this.venues.push(removedVenue)
      this.assignedVenues.splice(this.assignedVenues.findIndex(p => p.ID == venueID), 1);

    },
    /*
      - Sends a POST API with an object contatining the user_id from the props and its' list of venues.
    */
    updateVenues() {
      let obj = {};
      this.$set(obj, "user_id", this.user.id);
      this.$set(obj, "venue_ids", this.user.venues.map(String));

      this.Instance.post("update/user/venues", obj)
        .then(function(response) {
          console.log(response);
        })
        .catch(function(error) {
          console.error(error);
        });
    }
  },
  mounted() {
    this.getVenues();
  }
};
</script>

<style>
a,
button {
  transition: color 0.3s ease;
}

input[type="checkbox"],
a,
button {
  cursor: pointer;
}

button {
  margin: 0;
  padding: 0;
  border: 0;
  background: none;
  font-size: 100%;
  vertical-align: baseline;
  font-family: inherit;
  font-weight: inherit;
  color: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:focus {
  outline: 0;
}

.hidden {
  display: none;
}

.header:before {
  content: "";
  display: block;
  position: absolute;
  top: 13px;
  left: 13px;
  width: 65px;
  height: 65px;
  z-index: 1;
  opacity: 1;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNNiAxMmgxMHYxaC0xMHYtMXptNy44MTYtM2gtNy44MTZ2MWg5LjA0N2MtLjQ1LS4yODMtLjg2My0uNjE4LTEuMjMxLTF6bS03LjgxNi0yaDYuNWMtLjEzNC0uMzItLjIzNy0uNjU2LS4zMTktMWgtNi4xODF2MXptMTMgMy45NzV2Mi41NjhjMCA0LjEwNy02IDIuNDU3LTYgMi40NTdzMS41MTggNi0yLjYzOCA2aC03LjM2MnYtMjBoOS41Yy4zMTItLjc0OS43NjMtMS40MjQgMS4zMTYtMmgtMTIuODE2djI0aDEwLjE4OWMzLjE2MyAwIDkuODExLTcuMjIzIDkuODExLTkuNjE0di0zLjg4NmMtLjYyMy4yNi0xLjI5Ny40MjEtMiAuNDc1em00LTYuNDc1YzAgMi40ODUtMi4wMTUgNC41LTQuNSA0LjVzLTQuNS0yLjAxNS00LjUtNC41IDIuMDE1LTQuNSA0LjUtNC41IDQuNSAyLjAxNSA0LjUgNC41em0tMi4xNTYtLjg4MmwtLjY5Ni0uNjk2LTIuMTE2IDIuMTY5LS45OTItLjk0MS0uNjk2LjY5NyAxLjY4OCAxLjYzNyAyLjgxMi0yLjg2NnoiLz48L3N2Zz4=");
  background-repeat: no-repeat;
  background-position: center;
}

.todoapp {
  background: #fff;
  padding: 13px;
  margin: 130px 0 40px 0;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 0 13px rgba(0, 0, 0, 0.2);
}

.todoapp input::-webkit-input-placeholder {
  font-weight: 400;
  color: #ddd;
}

.todoapp input::-moz-placeholder {
  font-weight: 400;
  color: #ddd;
}

.todoapp input::input-placeholder {
  font-weight: 400;
  color: #ddd;
}

.new-todo,
.edit {
  position: relative;
  margin: 0;
  width: 100%;
  font-size: 24px;
  font-weight: bold;
  font-family: inherit;
  font-weight: inherit;
  line-height: 1.4em;
  border: 0;
  color: inherit;
  box-sizing: border-box;
}

.new-todo {
  padding: 16px 60px 16px 60px;
  border-radius: 10px;
  border: none;
  background: #efefef;
  transition: background 0.3s ease;
}
.new-todo:focus {
  background: #ddd;
}

.new-todo-button {
  position: absolute;
  display: inline-block;
  width: 65px;
  height: 65px;
  right: 13px;
  top: 13px;
  transition: opacity 0.3s ease;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTIgMmM1LjUxNCAwIDEwIDQuNDg2IDEwIDEwcy00LjQ4NiAxMC0xMCAxMC0xMC00LjQ4Ni0xMC0xMCA0LjQ4Ni0xMCAxMC0xMHptMC0yYy02LjYyNyAwLTEyIDUuMzczLTEyIDEyczUuMzczIDEyIDEyIDEyIDEyLTUuMzczIDEyLTEyLTUuMzczLTEyLTEyLTEyem02IDEzaC01djVoLTJ2LTVoLTV2LTJoNXYtNWgydjVoNXYyeiIvPjwvc3ZnPg==");
  background-repeat: no-repeat;
  background-position: center;
}

.main {
  position: relative;
  z-index: 2;
}

.completed-wrapper {
  border-bottom: 1px solid #e6e6e6;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.toggle-all {
  width: 1px;
  height: 1px;
  border: none;
  /* Mobile Safari */
  opacity: 0;
  position: absolute;
  right: 100%;
  bottom: 100%;
}

.toggle-all + label {
  font-size: 12px;
  top: 13px;
  left: 13px;
  z-index: 99;
  cursor: pointer;
  color: #999;
  transition: color 0.3s ease;
}
.toggle-all + label:hover {
  color: #000;
}

.toggle-all + label:before {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yNCA2LjI3OGwtMTEuMTYgMTIuNzIyLTYuODQtNiAxLjMxOS0xLjQ5IDUuMzQxIDQuNjg2IDkuODY1LTExLjE5NiAxLjQ3NSAxLjI3OHptLTIyLjY4MSA1LjIzMmw2LjgzNSA2LjAxLTEuMzE0IDEuNDgtNi44NC02IDEuMzE5LTEuNDl6bTkuMjc4LjIxOGw1LjkyMS02LjcyOCAxLjQ4MiAxLjI4NS01LjkyMSA2Ljc1Ni0xLjQ4Mi0xLjMxM3oiLz48L3N2Zz4=");
  background-repeat: no-repeat;
  background-position: center;
  content: "";
  font-size: 22px;
  opacity: 0.2;
  width: 57px;
  height: 40px;
  display: inline-block;
  vertical-align: middle;
}

.toggle-all:checked + label:before {
  opacity: 1;
}

.todo-list {
  margin: 13px 0;
  padding: 0;
  list-style: none;
}

.todo-list li {
  position: relative;
  font-size: 24px;
  border-radius: 10px;
  transition: background 0.3s ease;
}
.todo-list li:hover {
  background: #f8f8f8;
}

.todo-list li:last-child {
  border-bottom: none;
}

.todo-list li.editing {
  border-bottom: none;
  padding: 0;
  background: #fff;
}

.todo-list li.editing .edit {
  display: block;
  width: calc(100% - 43px);
  padding: 12px 16px;
  margin: 0 0 0 43px;
}

.todo-list li.editing .view {
  display: none;
}

.todo-list li .toggle {
  text-align: center;
  width: 40px;
  /* auto, since non-WebKit browsers doesn't support input styling */
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  border: none;
  /* Mobile Safari */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.todo-list li .toggle {
  opacity: 0;
}

.todo-list li .toggle + label {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTIgMmM1LjUxNCAwIDEwIDQuNDg2IDEwIDEwcy00LjQ4NiAxMC0xMCAxMC0xMC00LjQ4Ni0xMC0xMCA0LjQ4Ni0xMCAxMC0xMHptMC0yYy02LjYyNyAwLTEyIDUuMzczLTEyIDEyczUuMzczIDEyIDEyIDEyIDEyLTUuMzczIDEyLTEyLTUuMzczLTEyLTEyLTEyeiIvPjwvc3ZnPg==");
  background-repeat: no-repeat;
  background-position: 15px center;
}

.todo-list li .toggle:checked + label {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTIgMmM1LjUxNCAwIDEwIDQuNDg2IDEwIDEwcy00LjQ4NiAxMC0xMCAxMC0xMC00LjQ4Ni0xMC0xMCA0LjQ4Ni0xMCAxMC0xMHptMC0yYy02LjYyNyAwLTEyIDUuMzczLTEyIDEyczUuMzczIDEyIDEyIDEyIDEyLTUuMzczIDEyLTEyLTUuMzczLTEyLTEyLTEyem00LjM5MyA3LjVsLTUuNjQzIDUuNzg0LTIuNjQ0LTIuNTA2LTEuODU2IDEuODU4IDQuNSA0LjM2NCA3LjUtNy42NDMtMS44NTctMS44NTd6Ii8+PC9zdmc+");
}

.todo-list li label {
  word-break: break-all;
  padding: 15px 15px 15px 60px;
  display: block;
  line-height: 1.2;
  transition: color 0.4s;
}

.todo-list li.completed label {
  text-decoration: line-through;
}

.todo-list li .destroy {
  display: none;
  position: absolute;
  top: 0;
  right: 10px;
  bottom: 0;
  width: 40px;
  height: 40px;
  margin: auto 0;
  font-size: 30px;
  color: #cc9a9a;
  margin-bottom: 4px;
  transition: color 0.2s ease-out;
}

.todo-list li .destroy:hover {
  color: #af5b5e;
}

.todo-list li .destroy:after {
  content: "";
  width: 40px;
  height: 40px;
  display: inline-block;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTIgMmM1LjUxNCAwIDEwIDQuNDg2IDEwIDEwcy00LjQ4NiAxMC0xMCAxMC0xMC00LjQ4Ni0xMC0xMCA0LjQ4Ni0xMCAxMC0xMHptMC0yYy02LjYyNyAwLTEyIDUuMzczLTEyIDEyczUuMzczIDEyIDEyIDEyIDEyLTUuMzczIDEyLTEyLTUuMzczLTEyLTEyLTEyem01IDE1LjUzOGwtMy41OTItMy41NDggMy41NDYtMy41ODctMS40MTYtMS40MDMtMy41NDUgMy41ODktMy41ODgtMy41NDMtMS40MDUgMS40MDUgMy41OTMgMy41NTItMy41NDcgMy41OTIgMS40MDUgMS40MDUgMy41NTUtMy41OTYgMy41OTEgMy41NSAxLjQwMy0xLjQxNnoiLz48L3N2Zz4=");
  background-repeat: no-repeat;
  background-position: center;
}

.todo-list li:hover .destroy {
  display: block;
}

.todo-list li .edit {
  display: none;
}

.footer {
  color: #999;
  padding: 10px 0 0 17px;
  border-top: 1px solid #e6e6e6;
  display: flex;
  justify-content: space-between;
}

.todo-count {
  text-align: left;
}

.todo-count strong {
  font-weight: bold;
  color: #555;
}

.filters {
  margin: 0;
  padding: 0;
  list-style: none;
  right: 0;
  left: 0;
}
.filters li {
  display: inline-block;
  padding: 0 3px;
}
.filters li a {
  color: inherit;
  text-decoration: none;
}
.filters li a:hover,
.filters li a.selected {
  color: #000;
}

.clear-completed {
  display: inline-block;
  line-height: 40px;
  color: #999;
}

.clear-completed,
html .clear-completed:active {
  text-decoration: none;
  cursor: pointer;
}

.clear-completed:hover {
  color: #000;
}
</style>
