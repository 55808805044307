<template>
  <div class="row">
    <div class="col-lg-8 col-md-7">
      <card>
        <vue-tabs value="Description">
          <v-tab title="General Info" id="general_info">
            <edit-profile-form :user="user"> </edit-profile-form>
          </v-tab>
          <v-tab title="Subscriptions" id="subscriptions">
            <edit-subscriptions-form> </edit-subscriptions-form>
          </v-tab>
          <v-tab title="Create New User" id="new_user">
            <add-users> </add-users>
          </v-tab>
          <v-tab title="Assign User Venues" id="user_venues">
            <assign-venues> </assign-venues>
          </v-tab>
        </vue-tabs>
      </card>
    </div>
    <div class="col-lg-4 col-md-5">
      <user-card :user="user"> </user-card>
    </div>
  </div>
</template>
<script>
import EditProfileForm from "./UserProfile/EditProfileForm.vue";
import EditSubscriptionsForm from "./UserProfile/EditSubscriptionsForm.vue";
import AddUsers from "./UserProfile/AddUsers.vue";
import UserCard from "./UserProfile/UserCard.vue";
import AssignVenues from "./UserProfile/AssignVenues.vue";
import { initAPI } from "src/gateway/api-instance.js";
import { TabContent } from "vue-form-wizard";

export default {
  components: {
    EditProfileForm,
    EditSubscriptionsForm,
    UserCard,
    AddUsers,
    AssignVenues,
    TabContent
  },
  data() {
    return {
      user: {}
    };
  },
  computed: {
    Instance() {
      return initAPI(this.$cookies.get("Token"), process.env.VUE_APP_API_URL);
    }
  },
  methods: {
    getUserData() {
      var vm = this;
      vm.Instance.get("users?email=" + vm.$cookies.get("Email")).then(
        response => {
          //console.log("user", response.data);
          vm.user = response.data;
        }
      );
    }
  },
  mounted() {
    this.getUserData();
  }
};
</script>
<style lang="scss">
.vue-form-wizard .wizard-icon-circle.tab_shape {
  background-color: #9a9a9a !important;
  color: white;
}
.vue-form-wizard .wizard-tab-content {
  display: flex; // to avoid horizontal scroll when animating
  .wizard-tab-container {
    display: block;
    animation: fadeIn 0.5s;
  }
}
span.error {
  color: #e74c3c;
  font-size: 20px;
  display: flex;
  justify-content: right;
}
</style>
